import React from 'react';
import ReactTooltip from 'react-tooltip'
import ComposerMarketplaceMatchCard from './ComposerMarketplaceMatchCard';
import AIGradientSparklesIcon from '../../images/icons/ai-gradient-sparkles-icon.svg';

export default function ComposerMarketplaceMatch(props) {
  let marketplaceProduct = props.marketplaceMatch
  let renderedFromQuickCompose = props.qc
  return (
    <div
      className={`flex flex-col items-start marketplace-match__wrapper ${renderedFromQuickCompose ? 'box mt-4 marketplace-match__wrapper-qc' : ''}`}
      style={{
        backgroundColor: renderedFromQuickCompose ? '#F0FCFC' : 'rgba(16,218,218,0.0902)',
        padding: "1rem 2rem"
      }}
    >
      <ReactTooltip className={"marketplace-match-tooltip"}/>
      <div className="text-coal flex align-center marketplace-match__icon-and-text-wrapper">
        <img src={AIGradientSparklesIcon} alt="Auto Awesome AI Icon with Gradient Sparkles" className="sparkle-icon sparkle-icon-ai-marketplace-match" style={{transform: "translate(-7px, -7px)"}}/>
        <div className={`text-content-link-percentage text-left ${renderedFromQuickCompose ? 'text-content-wrapper-marketplace-match-qc' : 'text-content-wrapper-marketplace-match'}`}>
          <p className="text-coal">Also available in <a href="/marketplace" target='_blank' rel='noopener noreferrer'>URLgenius Marketplace </a> at <strong>{marketplaceProduct.client_split_percentage}%</strong> payout!</p>
        </div>
      </div>
      <ComposerMarketplaceMatchCard
        product={marketplaceProduct}
        qc={renderedFromQuickCompose}
      />
      <div className={`relative marketplace-match-checkbox-label-info flex items-center composer-product-card mt-4 ${renderedFromQuickCompose ? 'composer-product-card-qc' : ''}`} style={{ top: '1px', marginLeft: "2rem" }}>
        <label className="inline-flex items-center">
          <input type="checkbox"
            id="marketplace_create"
            className="form-checkbox text-blueGreen"
            defaultChecked={props.composeAsMarketplaceLink}
            onChange={props.handleComposeAsMarketplaceLink}
            style={{width: "1.3rem", height: "1.3rem", transform: "translateY(-2px)"}}
          />
          <span className={`ml-2 text-coal ${renderedFromQuickCompose ? 'earn-as-text-qc' : 'earn-as-text'}`}>Earn {marketplaceProduct.client_split_percentage}% as a URLgenius Marketplace link?</span>
        </label>
        <div className="composer-match-info-circle__wrapper">
          <ion-icon data-tip="Marketplace product links are powered by Amazon Attribution tags and feature a 14-day cookie window. Associates tags are excluded from the links. Contact us with questions about commissions and payouts!"  name="information-circle-outline" style={{color: "#119797", marginLeft: "4px",transform: "translateY(-1px)"}} class="composer-match-info-circle"></ion-icon>
        </div>
      </div>
    </div>
  );
}