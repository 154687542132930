import React from 'react';

export default function ComposerMarketplaceMatchCard({ product, qc }) {

  const totalReviewFormatter = (value) => {
    if (!value) {
      return "";
    }
    if (value >= 1000000) {
      const formattedValue = (value / 1000000).toFixed(1);
      return formattedValue.endsWith('.0') ? `${Math.floor(value / 1000000)}m` : `${formattedValue}m`;
    } else if (value >= 1000) {
      const formattedValue = (value / 1000).toFixed(1);
      return formattedValue.endsWith('.0') ? `${Math.floor(value / 1000)}k` : `${formattedValue}k`;
    }
    return value.toString();
  }

  const Stars = ({ rating }) => {
    return (
      <div
        className="marketplace-stars"
        style={{ '--rating': rating }}
        aria-label={`Rating of this product is ${rating} out of 5.`}
      ></div>
    );
  };
  return (
    <div className={`creator-marketplace-card product-card card composer-product-card ${qc ? 'composer-product-card-qc' : ''}`} style={{marginLeft: "2rem"}}>
      <div className="product-image-and-data product-image-and-data__composer-card flex">
        <div className="flex justify-center composer-marketplace-card-product-image__wrapper mr-6">
          <img
            className="marketplace-composer-card-product-image"
            src={product.product_image_url}
            alt="product image"
            style={{ borderRadius: "6px" }}
          />
        </div>
        <div className="product-data__wrapper">
          <div className={`flex justify-center mt-2 marketplace-card-product-name marketplace-card-product-name__composer-card ${qc ? 'marketplace-card-product-name__composer-card-qc' : ''}`}>
            <p>{product.product_name}</p>
          </div>
          <div className="asin-rating-reviews flex items-center mt-2">
            <p className="mr-2 text-coal" style={{fontSize: ".85rem"}}>{product.asin}</p>
            <Stars rating={product.avg_rating ? product.avg_rating.toFixed(2) : 0.0} />
            <p className="" style={{fontSize: ".78rem", marginLeft: "2px", color: "#3c4c5dab"}}> 
              {totalReviewFormatter(product.total_reviews)}
            </p>
          </div>
          <div className="price-and-bonus-row flex mt-4">
            <div className="mr-2 text-coal">
              Price:{" "}
              <span className="marketplace-card-price-tag">
                ${parseFloat(product.price).toFixed(2)}
              </span>
            </div>
            <div className="ml-2 text-coal">
              Bonus:{" "}
              <p
                style={{ color: "green" }}
                className="tag marketplace-card-commission-tag">
                  {parseFloat(product.client_split_percentage).toFixed(0)}%
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}